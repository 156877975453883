.grid-container-snippets{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-gap: 1em; */
    /* grid-auto-rows: minmax(100px, auto); */
    /* padding: 100px; */
    /* margin-top: 5%;

    padding: 14px 16px; */

    grid-auto-rows: fit-content(1em);
}

.grid-items-snippets{
        display: grid;
        grid-gap: 1.5em;
        grid-template-columns: repeat(2, 1fr);
        grid-column-start: 0;
        grid-column-end: 1;
}

#subtitle{
    color: rgb(192, 192, 192);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    font-size: 0.9em;
}

#main{
    color: rgb(192, 192, 192);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 0.9em;
}