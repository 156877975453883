#buttons{
    /* background-color: rgb(15, 42, 73); */
    background-color: rgba(101, 158, 243, 0.411);
    border-radius: 5px;
    padding: 8px;
    transition: box-shadow 0.5s;
    transition: all .3s ease-in-out 0s;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    box-shadow: 0 0 0 1px rgba(156, 195, 253, 0.733);
    /* position: absolute; */
    /* transition: transform 0.5s; */
    
}

#buttons:hover{
    /* border: 1px solid rgba(255, 255, 255, 0.233); */
    /* inner glow */
    box-shadow: 0 0 0 1px rgba(229, 238, 253, 0.733);
    transform: translateY(-5px);
    /* transform: translateX(3px); */
    /* transform: scale(1.01); */
}

.text{
    /* position text in the center of div */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    margin-left: 10%;
}