
.button2{
    transition: transform .5s;
    padding-right: 10px;    
    width: 33px;
    height: 33px;
    text-decoration: none;
}
.button2:hover{
    transform: translateY(-5px);
}

#buttonNew{
    /* padding: 4px; */
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.grid-container-buttons{
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-gap: 1em;
    /* grid-auto-rows: minmax(100px, auto); */
    /* padding: 100px; */
    /* margin-top: 5%; */
    /* margin-left: 20%; */
    /* margin-right: 20%; */
    padding: 14px 0px;

    /* grid-auto-rows: fit-content(1em); */
}

.grid-items-buttons{
        /* display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(4, 1fr);
        grid-column-start: -1;
        grid-column-end: 3; */
        display: inline-block;

}
