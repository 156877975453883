#resumeMain{
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
    transition: transform .5s;
}

#resumeMain:hover{
    transform: scale(1.02);
}

