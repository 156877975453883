.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    left: 0;
    /* bottom: 0;
    width: 100%; */
    height: 100;
    color: rgba(255, 255, 255, 0.144);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: color .3s;
    cursor: pointer;
  }

  .footer:hover{
      color: rgba(255, 255, 255, 0.308);
  }

  #footerParent{
    position: absolute;
    bottom: -10%;
    width: 100%;
  }