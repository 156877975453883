iframe {
    /* display: block; */
    border-style:none;
    /* position: relative; */
    width: 100%;
    height: 900px;
    border-radius: 5px;
    margin-top: 10%;
    /* left: 50%; */
}

.resume{
    animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
} 