
ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;

}

.navBar{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    backdrop-filter: saturate(100%) blur(16px);
}

nav{
    margin-left: 20%;
    margin-right: 20%;
    /* position: fixed; top: 0; 
    backdrop-filter: saturate(100%) blur(16px); */
}

@media (max-width: 900px){
  nav{
    margin-left: 10%;
    margin-right: 10%;
    /* position: fixed; top: 0; 
    backdrop-filter: saturate(100%) blur(16px); */
}
  }

/* .navBar{ */
    /* position: fixed; top: 0; 
    backdrop-filter: saturate(100%) blur(16px); */
/* } */


li {
  float: right;
}

#nav-left {
  float: left;
}

li a {
  display: block;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  transition: color .3s;
  
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  color: rgb(184, 184, 184);
}


@media (max-width: 900px){
    .links{
        display:none;
    }

}