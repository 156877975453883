#page{
    position: relative;
}

/* make background color black */
body {
    /* background-color: rgb(14, 14, 14); */
    background-color: rgb(0, 0, 0);
animation: fadein 2s;
-moz-animation: fadein 2s; /* Firefox */
-webkit-animation: fadein 2s; /* Safari and Chrome */
-o-animation: fadein 2s; /* Opera */
position: relative;
}

/* make nav bar 10% transparent */
.navbar {
    background-color: rgba(0, 0, 0, 0.1);
}

/* 2 column css grid */


#test{
    color: white;
}

.grid-container{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-gap: 5em; */
    /* grid-auto-rows: minmax(100px, auto); */
    /* padding: 100px; */
    margin-top: 10%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 14px 16px;

    grid-auto-rows: fit-content(1.5em);
}

.grid-items{
        display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(2, 1fr);
        /* grid-column-start: 1;
        grid-column-end: 2; */
}


#name{
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.5em;
}

p{
    color: white;
}

@media (max-width: 900px){
.grid-container{
    margin-top: 20%;
    margin-left: 10%;
    margin-right: 10%;
}
}

@media (min-width: 100px){
    .grid-items{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 900px){
    .grid-items{
        grid-template-columns: repeat(2, 1fr);
    }
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}