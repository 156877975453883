.grid-container-work{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-gap: 1em;
    /* grid-auto-rows: minmax(100px, auto); */
    /* padding: 100px; */
    /* margin-top: 5%; */
    /* margin-left: 20%; */
    /* margin-right: 20%; */
    padding: 14px 0px;

    grid-auto-rows: fit-content(1em);
}

.grid-items-work{
        display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(2, 1fr);
        grid-column-start: 0;
        grid-column-end: 2;
}

a{
    text-decoration: none;
}