p{
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1{
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-top: -50px; */
}

#about{
    margin-top: -0.1em;
}

span{
    font-weight: bold;
}

/* #about{

    border-radius: 10px;
    padding: 1em;
    transition: border .3s;
}

#about:hover{
    border: 1px solid white;
} */